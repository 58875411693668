.custom-backdrop {
	background: #3e38a9 !important;
}

.select__indicator-separator {
	display: none;
}
.text-underline {
	text-decoration: underline;
}

.cursor-pointer {
	cursor: pointer;
}

.customDatePickerWidth,
.customDatePickerWidth > div.react-datepicker-wrapper,
.customDatePickerWidth
	> div
	> div.react-datepicker__input-container
	.customDatePickerWidth
	> div
	> div.react-datepicker__input-container
	input {
	width: 100%;
}

.rdrCalendarWrapper,
.rdrMonth {
	width: 100% !important;
}

iframe {
	display: none !important;
}

.search-modal .modal-content {
	height: 100vh;
}

.custom-calendar .rdrStartEdge,
.rdrInRange,
.rdrEndEdge,
.rdrStartEdge {
	color: #422468 !important;
}

.custom-calendar .rdrDayToday .rdrDayNumber > span::after {
	background-color: #422468 !important;
}
.search-modal-mobile {
	margin: 0;
}
.MuiTooltip-popper > .MuiTooltip-tooltipPlacementTop {
	color: #121213;
	background: #fff;
	font-weight: 400;
	font-size: 14px;
	line-height: 19.5px;
	text-align: left;
	box-shadow: 0px 2px 12px 2px rgba(0, 0, 0, 0.07);
	border-radius: 8px;
	padding: 16px;
}
